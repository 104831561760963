$icon-path: '../../../icons/src/system-icons/';
@import 'ag-grid-enterprise/styles/ag-grid.css';
@import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
@import '~@dome9/berries/base';
@import '~@dome9/berries/components';
@import '~@dome9/berries/utilities';
@import 'assets/styles/colors';
@import 'assets/styles/buttons';
@import 'assets/styles/common';
@import 'assets/styles/forms';
@import 'assets/styles/typography';
@import 'assets/styles/print';
@import 'berries-candidates';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // 10px
}

body {
    box-sizing: border-box;
    font-family: $font-family-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: $font-family-code;
}

.webapp-iframe-placeholder-element {
    position: relative;
    height: 100%;
    width: 100%;
}

.webapp-iframe {
    position: fixed;
    border: none;
}

// style for all system-icons from berries
.svg-system-icon {
    display: unset;
    vertical-align: unset;
}
