@import 'assets/styles/_fonts';
$font-family-default: 'Open Sans', system-ui, sans-serif;
$font-family-code: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;

.title {
    &-large {
        font-size: 22px;
        line-height: 1.5;
        font-weight: normal;
        color: $cp-greyscale-900;
    }

    &-extra-large {
        font-size: 32px;
    }

    &-preview {
        &::after {
            font-size: 12px;
            content: '  (Preview)';
        }
    }

    &-page {
        font-size: 18px;
        line-height: 1.5;
        font-weight: normal;
        color: $cp-greyscale-900;
    }

    &-section {
        font-size: 15px;
        line-height: 1.5;
        font-weight: normal;
        color: $cp-greyscale-900;
    }
    &-medium {
        font-size: 13px;
    }
}

.text {
    &-body {
        font-size: 13px;
        line-height: 1.5;
        font-weight: normal;
        color: $cp-greyscale-900;

        &-disable {
            color: $cp-greyscale-600;
        }

        &-link {
            color: $cp-primary-600;
        }

        &-description {
            color: $cp-greyscale-700;
        }

        &-italic {
            font-style: italic;
        }

        &-bold {
            font-weight: bold;
        }
        &-xtrabold {
            font-weight: 900;
        }
        &-large {
            font-size: 14px;
        }
    }

    &-label {
        font-size: 12px;
        line-height: 1.5;
        font-weight: normal;
        color: $cp-greyscale-900;
    }

    &-header {
        text-transform: uppercase;

        &-primary {
            color: $cp-primary-800;
        }

        &-model {
            color: $cp-greyscale-100;
        }
    }

    &-error {
        color: $cp-status-error;
        font-size: 13px;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-uppercase {
        text-transform: uppercase;
    }
}

a {
    text-decoration: none;
}
