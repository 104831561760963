.form {
    font-size: 1.3rem;

    &__input {
        width: 100%;
        line-height: 3;
        background: none;
        @apply border-b;
        border-radius: 0;
        outline: none;
        transition: all 0.25s;
        margin-bottom: 1rem;
        padding: 0 10px;
    }
    &__input-text-error {
        color: #f24e4e;
    }
    &__input-error {
        border-bottom: 1px solid #f24e4e;
    }

    &__title {
        font-weight: 100;
    }

    a {
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;
        color: $cp-primary-600;
        -webkit-text-decoration-skip: objects;
    }
}

.text-field {
    z-index: 1;
}
