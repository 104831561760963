.theme {
    svg {
        background-color: unset;
    }
}

.react-flow__custom-edge-path {
    fill: none;
    stroke-width: 6;
    stroke-linecap: butt;
}

.edge-stop--start {
    stop-color: var(--stopColorStart);
}
.edge-stop--end {
    stop-color: var(--stopColorEnd);
}
.edge-stop--arrow {
    fill: var(--stopColorEnd);
}
.react-flow__edge-fat-arrow {
    offset-distance: 50%;
}
.react-flow__edge-circle {
    offset-distance: 50%;
}

.react-flow__node.react-flow__node-special.selected {
    @apply ring-info rounded ring-2 ring-offset-2;
}

[data-id='Policy_Two'] {
    @apply ring-info rounded ring-2 ring-offset-2;
}

.react-flow__edge.selected {
    @apply border-info border;
}

.bg-standout-default {
    background-color: rgb(181, 33, 255);
}

.bg-standout-lighter {
    background-color: rgb(217, 140, 255);
}

.text-3xs {
    font-size: 8px;
}

.theme {
    svg {
        background-color: unset;
    }
}

.suggestions {
    .white-fill {
        svg {
            fill: #fff;
        }
    }
}

.node-icon-container {
    svg {
        width: 48px;
        height: 48px;
    }

    &.white-foreground {
        path {
            fill: white;
        }

        .design-system {
            path {
                fill: none;
                stroke: white;
            }
        }

        g {
            fill: rgba(0, 0, 0, 0) !important;
        }
    }
}

.group-item-icon-container {
    margin-right: 5px;

    svg {
        height: 25px;
        width: 25px;
    }

    &.dark-group-item-icon-container {
        path {
            fill: rgba(0, 0, 0, 0.8);
        }
        rect {
            display: none;
        }
        svg {
            // This is very hacky and temporary until we separate icons for AwsDbInstance
            &:has(g#Icon-Architecture-Category\/16\/Database_16) {
                height: 40px;
                width: 40px;
                margin: 0 -8px;
            }
        }
    }
}

.popover-icon-container {
    margin-right: 5px;

    svg {
        height: 42px;
        width: 42px;
    }

    &.black-foreground {
        path {
            fill: rgba(0, 0, 0, 0.8);
        }

        .design-system {
            path {
                fill: none;
                stroke: black;
            }
        }

        g {
            fill: rgba(0, 0, 0, 0) !important;
        }
    }
}

.exposure-severity-list {
    tr {
        border-bottom: none;
    }
}

.popover-item {
    padding-bottom: 5px;

    &:first-child {
        padding-top: 20px;
    }

    &:last-child {
        padding-bottom: 20px;
    }
}

.popover.popover {
    --svg-display: none;
    @apply backdrop-blur-xl bg-weakest bg-none filter;
}

.react-flow__edgelabel-renderer {
    pointer-events: initial;
}

.edge-link:hover {
    text-decoration: underline;
}

.exposure-severity-list {
    .leading-none:first-child {
        min-width: 130px;
        vertical-align: bottom;
        padding-bottom: 13px;
    }

    .count-box {
        min-width: 40px;
    }
}

.asset-node {
    cursor: default;
}

.insight-container {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 70px calc(100vh - 135px);

    max-height: 100%;
    height: 100%;

    .insight-header {
        grid-column: 1 / 3;
        grid-row: 1 / 2;

        padding: 20px 20px;

        .insight-environment {
            width: 250px;
            margin-right: 20px;
        }

        .insight-query {
            width: 400px;
            margin-right: 20px;
        }

        .insight-search {
            width: 350px;
            margin-right: 20px;
        }

        .insight-node-limit {
            width: 140px;
            margin-right: 20px;
            position: relative;

            .text-field {
                position: absolute;
                width: 100%;
                left: 0;
            }

            .node-text {
                position: absolute;
                right: 55px;
                top: 6px;
                z-index: 9999;
            }
        }

        .insight-execute {
            .btn {
                width: 100px;
            }
            margin-right: 20px;
        }

        .insight-clear {
            .btn {
                width: 100px;
            }
        }
    }

    .insight-graph {
        grid-column: 1 / 2;
        grid-row: 2 / 3;

        position: relative;

        .canvas-container {
            height: 100%;
            width: 100%;
            border: 1px #e8e9eb solid;
        }

        .graph-legend {
            position: absolute;

            top: 10px;
            left: 25px;

            li {
                display: list-item;
                list-style-type: disc;
            }
        }
    }

    .insight-details {
        grid-column: 2 / 3;
        grid-row: 2 / 3;

        padding: 0 20px;
        overflow: auto;
        height: 100%;

        .exposure-level {
            font-weight: 700;
        }

        .details {
            width: 100%;

            textarea {
                background-color: #f7f7f7;
                resize: none;
                border: none;
                margin-top: 10px;
                width: 100%;
                height: calc(100vh - 315px);

                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }

        .node-limit {
            .text-field {
                display: inline-block;
                margin-left: 20px;
                width: 80px;
            }
        }

        .entity-title {
            font-size: 25px;
        }

        .entity-title-container {
            display: flex;
            justify-content: space-between;
            .type-title {
                font-size: 20px;
            }

            .btn {
                display: inline-block;
            }
        }
    }
}

.perimeter-exposure {
    .section-title {
        font-size: 15px;
        color: #004099;
    }
}
