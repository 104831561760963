/* Open Sans Font */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/Light/OpenSans-Light.eot?v=1.1.0');
    src:
        url('../fonts/open-sans/Light/OpenSans-Light.eot?#iefix&v=1.1.0') format('embedded-opentype'),
        url('../fonts/open-sans/Light/OpenSans-Light.woff2?v=1.1.0') format('woff2'),
        url('../fonts/open-sans/Light/OpenSans-Light.woff?v=1.1.0') format('woff'),
        url('../fonts/open-sans/Light/OpenSans-Light.ttf?v=1.1.0') format('truetype'),
        url('../fonts/open-sans/Light/OpenSans-Light.svg?v=1.1.0#Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/Regular/OpenSans-Regular.eot?v=1.1.0');
    src:
        url('../fonts/open-sans/Regular/OpenSans-Regular.eot?#iefix&v=1.1.0') format('embedded-opentype'),
        url('../fonts/open-sans/Regular/OpenSans-Regular.woff2?v=1.1.0') format('woff2'),
        url('../fonts/open-sans/Regular/OpenSans-Regular.woff?v=1.1.0') format('woff'),
        url('../fonts/open-sans/Regular/OpenSans-Regular.ttf?v=1.1.0') format('truetype'),
        url('../fonts/open-sans/Regular/OpenSans-Regular.svg?v=1.1.0#Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/Semibold/OpenSans-Semibold.eot?v=1.1.0');
    src:
        url('../fonts/open-sans/Semibold/OpenSans-Semibold.eot?#iefix&v=1.1.0') format('embedded-opentype'),
        url('../fonts/open-sans/Semibold/OpenSans-Semibold.woff2?v=1.1.0') format('woff2'),
        url('../fonts/open-sans/Semibold/OpenSans-Semibold.woff?v=1.1.0') format('woff'),
        url('../fonts/open-sans/Semibold/OpenSans-Semibold.ttf?v=1.1.0') format('truetype'),
        url('../fonts/open-sans/Semibold/OpenSans-Semibold.svg?v=1.1.0#Semibold') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/Bold/OpenSans-Bold.eot?v=1.1.0');
    src:
        url('../fonts/open-sans/Bold/OpenSans-Bold.eot?#iefix&v=1.1.0') format('embedded-opentype'),
        url('../fonts/open-sans/Bold/OpenSans-Bold.woff2?v=1.1.0') format('woff2'),
        url('../fonts/open-sans/Bold/OpenSans-Bold.woff?v=1.1.0') format('woff'),
        url('../fonts/open-sans/Bold/OpenSans-Bold.ttf?v=1.1.0') format('truetype'),
        url('../fonts/open-sans/Bold/OpenSans-Bold.svg?v=1.1.0#Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

/* RobotoMono Font */
@font-face {
    font-family: 'RobotoMono';
    src: url('../fonts/RobotoMono/RobotoMono-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

/* Inter Font */
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
