.intelligence-visualization-node-icon-container {
    cursor: pointer;
    svg {
        width: 38px !important;
        height: 38px !important;
    }

    &.aws {
        svg * {
            filter: contrast(0) brightness(2);
        }
        &.service {
            svg {
                fill: none;
                width: 48px !important;
                height: 48px !important;
            }
        }
        g {
            fill: none;
        }
    }

    &.malicious {
        svg {
            fill: none;
        }
        svg * {
            filter: contrast(0) brightness(2);
        }
    }

    &.Default {
        svg * {
            filter: contrast(0) brightness(2);
        }
    }
}

.intelligence-visualization-popover-icon-container {
    margin-right: 5px;

    svg {
        height: 42px;
        width: 42px;
    }

    &.aws {
        svg * {
            filter: contrast(1) brightness(1) hue-rotate(180deg) saturate(0);
        }
    }
}
