.GSLBuilder {
    display: flex;
    width: 100%;

    .btn {
        color: #535353;
    }
    .btn:hover {
        color: black;
    }
    .gsl-builder-choices {
        flex-shrink: 0;
        display: flex;
        input {
            margin-right: 5px;
        }
    }
    .editor {
        background: white;
        flex-grow: 1;
        margin-right: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 600px;
        border-right: 1px solid lightgray;
        .gsl-editor {
            margin-top: 20px;
            min-height: 70px;
            background-color: #f5f5f5;
            display: inline-block;
            width: 100%;
            padding: 20px;
            font-size: 18px;
            flex-shrink: 0;
        }
        .gsl-sentence-free-text {
            margin-top: 20px;
            min-height: 70px;
            background-color: #f5f5f5;
            display: inline-block;
            width: 100%;
            padding: 20px;
            font-size: 18px;
            flex-shrink: 0;
            height: 300px;
            textarea {
                width: 100%;
                background: transparent;
                resize: none;
                border: 1px solid transparent;
                outline: none;
                height: 100%;
            }
        }

        .gsl {
            display: inline-block;
        }

        .section {
            font-size: 14px;
            font-weight: 500;
            color: rgb(117, 117, 117);
            .properties-section {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
            }
            .property-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                cursor: pointer;
                margin: 10px;
                width: 100px;
                .attribute {
                    font-size: 11px;
                }
                .property {
                    font-size: 14px;
                }
                .property:hover {
                    color: black;
                }
            }
        }

        .section {
            margin-top: 50px;
        }
    }
    .objectViewer {
        background: white;
        width: 200px;
        max-height: 500px;
        padding: 20px;
        overflow-y: auto;
        flex-shrink: 0;
    }
    .loader {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}
.gsl-input-popover {
    padding: 10px;
    width: 400px;
    .user-input {
        margin: 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        input {
            border: 1px solid gray;
            height: 30px;
            flex-grow: 1;
        }
    }
    .actions {
        display: flex;
        align-items: flex-end;
        margin-top: 10px;
        justify-content: flex-end;
        padding: 0 10px;
        gap: 10px;
    }
    select {
        border: 1px solid lightgray;
    }
}
