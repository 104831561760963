.top-remediations {
    .section-title {
        font-size: 15px;
        color: #004099;
    }
}

.exposure-severity-list {
    .top-title {
        padding-bottom: 3px;
    }
}

.group-row-hide-overflow {
    .ag-cell-value {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }
}
