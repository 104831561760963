.cp-common-btn {
    color: $cp-greyscale-200;
    background: #2e3f58;
    border: none;
    text-transform: uppercase;
    padding: 0 2rem;
    cursor: pointer;
    transition: all 0.25s;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    height: 2rem;
    line-height: 2;
    overflow: hidden;
}

.pointer {
    cursor: pointer;
}
