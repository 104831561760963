@import 'assets/styles/colors';

.App {
    background: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: unset;

    &__main {
        height: 100%;

        &--content {
            height: 100%;
            background-color: var(--color-aside-content);

            .hide {
                opacity: 0;
            }
        }
    }
}
