a:visited {
    color: inherit;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.inline-flex-center {
    @extend .inline-flex;
    align-items: center;
}
.flex-center {
    @extend .flex;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-align-start {
    display: flex;
    justify-content: flex-start;
}

.flex-column-center {
    @extend .flex-center;
    flex-direction: column;
}

.flex-center-space-between {
    @extend .flex-center;
    justify-content: space-between;
}

.flex-column-space-between {
    @extend .flex-column;
    justify-content: space-between;
}

.flex-align-start {
    @extend .flex;
    justify-content: flex-start;
}

.margin-bottom-1-rem {
    margin-bottom: 1rem;
}

.margin-left-1-rem {
    margin-left: 1rem;
}

.a-tag {
    //color: $anchor-tag-color;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

.feature-preview {
    position: relative;
    &:after {
        top: 0;
        right: 0;
        content: 'Preview';
        font-size: 9px;
        text-transform: none;
        color: white;
        position: absolute;
        line-height: 1em;
        padding: 1px 2px;
        background-color: $feature-preview-color;
    }
}

.text-transform-initial {
    text-transform: initial !important;
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
$top-navigation-bar-height: 44px;

.ag-header-row .ag-header-cell .ag-header-cell-resize {
    z-index: 1;
}

.ag-header-row .ag-header-cell .ag-header-cell-resize:after {
    display: block;
}
