@import '../../../assets/styles/colors';
@import '../../../assets/styles/typography';

.ellipsis-wrapped {
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden; /* keeps the element from overflowing its parent */
}

.ellipsis-wrapped-container {
    width: 650px;
}

.ellipsis-wrapped-menu-title-container {
    max-width: 150px;
}

.ellipsis-wrapped-section-title-container {
    max-width: 450px;
}
