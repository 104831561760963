$cp-primary-100: #e8f1ff;
$cp-primary-200: #e1f2ff;
$cp-primary-300: #bce3fe;
$cp-primary-400: #adbed7;
$cp-primary-500: #4fb4f1;
$cp-primary-550: #54b7ff;
$cp-primary-600: #3594ff;
$cp-primary-700: #3474c5;
$cp-primary-800: #2e3f58;
$cp-primary-900: #171f2c;

$cp-greyscale-100: #fff;
$cp-greyscale-150: #f6f7f9;
$cp-greyscale-200: #f7f7f7;
$cp-greyscale-250: #eaeaea;
$cp-greyscale-300: #e8e9eb;
$cp-greyscale-400: #ede4e8;
$cp-greyscale-500: #dedfe0;
$cp-greyscale-600: #a7a9ac;
$cp-greyscale-700: #b4b4b4;
$cp-greyscale-750: #d4d4d4;
$cp-greyscale-800: #7c7c7c;
$cp-greyscale-850: #c9e5fd59;
$cp-greyscale-860: #666;
$cp-greyscale-900: #333333;

$cp-transparent: rgba(0, 0, 0, 0);

$cp-status-error: #f24e4e;
$cp-status-pending: #ffb72d;
$cp-status-active: #57b409;

$cp-navbar-background: #e87e04;
$cp-navbar-divider: #404958;

$cp-left-menu-item: #aab4c4;
$cp-left-menu-item-active: #553248;
$cp-left-menu-item-box-shadow: #e45785;

$cp-left-submenu-item-active: #aedffc;
$cp-left-submenu-item-hover: #dff2fe;
$cp-left-submenu-item-title-color: #959595;
$cp-left-submenu-item-label-hover: #2a6496;

$anchor-tag-color: #1890ff;

$anchor-tag-color: #1890ff;

$has-role-color: #e87e04;
$has-role-hover-color: #fdc381;

$card-dark: #d4e9fb;
$card-info-fill: #2ea0ff;

$cp-table-row-hover-color: #d9dadb;
$feature-preview-color: #004cb7;

$cp-button-border: #d2d5d9;
